import { useEffect } from 'react';
import { createUseBoundEventCreator, DAEMON, useBrowserOnlyInjectSagaAndReducer } from '@dbh/redux-extra';
import { Map, List, fromJS } from 'immutable';
import { select, call, put, all, takeLatest } from 'redux-saga/effects';
import { LOAD_WEBPAGES_SUCCESS } from '@dbh/webpages-events-and-constants-redux';
import { isServerSideRendering } from '@dbh/environment';
import { makeSelectReactRouterPageType, makeSelectCurrentRouterState } from '@dbh/routing-redux';
import { selectLocationOriginFromServer } from '@dbh/ssr-data-redux';
import throwInServerSideRendering from '@dbh/throw-in-server-side-rendering';
import { makeSelectTemplateCode, makeSelectWebpagesLoadedAll, makeSelectCurrentWebpageRouteReference, makeSelectCurrentWebpageRouteTemplateCode } from '@dbh/webpages-redux';
import { makeSelectGtagDataToSendWithEveryEvent, analyticsEventSent } from '@dbh/analytics-redux';
import { createSimpleSelector } from '@dbh/reselect-extra';
import 'prop-types';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';

/*
 *
 * Constants: `@dbh/navigation-history-redux`.
 *
 */const REDUX_AND_SAGA_KEY="navigation-history-redux";const PAGE_LOCATION_CHANGED="navigation-history-redux/PAGE_LOCATION_CHANGED";const PAGE_LOCATION_ADDED_TO_NAVIGATION_HISTORY="navigation-history-redux/PAGE_LOCATION_ADDED_TO_NAVIGATION_HISTORY";const PAGE_DATA_PUT_INTO_LOCAL_STORAGE="navigation-history-redux/PAGE_DATA_PUT_INTO_LOCAL_STORAGE";const NAVIGATION_HISTORY_REMOVED_FROM_LOCAL_STORAGE="navigation-history-redux/NAVIGATION_HISTORY_REMOVED_FROM_LOCAL_STORAGE";const NAVIGATION_HISTORY_RESTORED_FROM_LOCAL_STORAGE="navigation-history-redux/NAVIGATION_HISTORY_RESTORED_FROM_LOCAL_STORAGE";const LOCAL_STORAGE_KEYS={NAVIGATION_HISTORY:"navigation-history"};

/**
 * Dispatched when the user navigates to a new page.
 * @return {Object} .
 */const pageLocationChanged=()=>({type:PAGE_LOCATION_CHANGED});const usePageLocationChanged=createUseBoundEventCreator(pageLocationChanged);var pageLocationChanged$1 = pageLocationChanged;

/**
 * @typedef {import('../types').PageData} PageData
 */ /**
 * Dispatched when the user navigates to a new page, to add the page location to
 * the navigation history.
 * @param {PageData} pageData .
 * @return {Object} .
 */const pageLocationAddedToNavigationHistory=withConformsTo("pageLocationAddedToNavigationHistory",[],a=>({type:PAGE_LOCATION_ADDED_TO_NAVIGATION_HISTORY,payload:{pageData:a}}));const usePageLocationAddedToNavigationHistory=createUseBoundEventCreator(pageLocationAddedToNavigationHistory);var pageLocationAddedToNavigationHistory$1 = pageLocationAddedToNavigationHistory;

/**
 * Dispatched when the user navigates to a new page, to put the navigation history
 * into the local storage.
 * @return {Object} .
 */const navigationHistoryPutIntoLocalStorage=()=>({type:PAGE_DATA_PUT_INTO_LOCAL_STORAGE});const useNavigationHistoryPutIntoLocalStorage=createUseBoundEventCreator(navigationHistoryPutIntoLocalStorage);var navigationHistoryPutIntoLocalStorage$1 = navigationHistoryPutIntoLocalStorage;

/**
 * Dispatched when the user closes the browser window or tab, to remove the navigation
 * history from the local storage.
 * @return {Object} .
 */const navigationHistoryRemovedFromLocalStorage=()=>({type:NAVIGATION_HISTORY_REMOVED_FROM_LOCAL_STORAGE});const useNavigationHistoryRemovedFromLocalStorage=createUseBoundEventCreator(navigationHistoryRemovedFromLocalStorage);var navigationHistoryRemovedFromLocalStorage$1 = navigationHistoryRemovedFromLocalStorage;

/**
 * @typedef {import('../types').NavigationHistory} NavigationHistory
 */ /**
 * Dispatched when we want to sync the navigation history state with the local
 * storage.
 * @param {NavigationHistory} navigationHistoryFromLocalStorage .
 * @return {Object} .
 */const navigationHistoryRestoredFromLocalStorage=withConformsTo("navigationHistoryRestoredFromLocalStorage",[],a=>({type:NAVIGATION_HISTORY_RESTORED_FROM_LOCAL_STORAGE,payload:{navigationHistoryFromLocalStorage:a}}));var navigationHistoryRestoredFromLocalStorage$1 = navigationHistoryRestoredFromLocalStorage;

const initialState=Map({navigationHistory:List()}),navigationHistoryReducer=function(a,b){switch(void 0===a&&(a=initialState),b.type){case NAVIGATION_HISTORY_RESTORED_FROM_LOCAL_STORAGE:{const{payload:{navigationHistoryFromLocalStorage:c}}=b;return a.set("navigationHistory",c)}case PAGE_LOCATION_ADDED_TO_NAVIGATION_HISTORY:{const{payload:{pageData:c}}=b;return a.update("navigationHistory",a=>{const b=a.push(c);return b})}default:return a}};/**
 * Manages the navigation history state.
 * @param {Immutable.Map} state The current state.
 * @param {Object} event .
 * @return {Immutable.Map} The next state.
 */var navigationHistoryReducer$1 = navigationHistoryReducer;

const selectNavigationHistoryState=a=>a.get(REDUX_AND_SAGA_KEY);var selectNavigationHistoryState$1 = selectNavigationHistoryState;

/**
 * @typedef {import('./types').NavigationHistory} NavigationHistory
 */ /**
 * Returns a selector that returns the navigation history data from the navigation
 * history state.
 * @return {NavigationHistory} .
 */const makeSelectNavigationHistoryData=()=>createSimpleSelector(selectNavigationHistoryState$1,["navigationHistory"]);var makeSelectNavigationHistoryData$1 = makeSelectNavigationHistoryData;

const abbreviations={UserArea:"UA",Request:"Req",DevOnly:"Dev",DevelopmentOnly:"Dev",Corporate:"Corp",Password:"Pwd",Reservation:"Resv",Information:"Info",InvoicesPaymentFormPage:"InvoicesForm",ViewInvoicesPaymentPage:"ViewInvoices",InsertSignUpVerificationCode:"SignUpVerCode",Page:"Pg",Lp:"","lp-dashboard-loyalty-user":"LoyaltyUserPg","lp-content-page-not-loyalty-user":"NotLoyaltyUserPg",Dashboard:"Dash",PhilosophyAndBenefits:"PAndB",termsandconditions:"TAndC",HowToEnableJsInBrowser:"JsEnableInBrowsr",Success:"Sucs"},regexToReplace=new RegExp(Object.keys(abbreviations).join("|"),"g"),replaceAbbreviations=a=>a.replace(regexToReplace,a=>abbreviations[a]),buildGtagNavigationEventName=withConformsTo("buildGtagNavigationEventName",[],(a,b)=>{const c=replaceAbbreviations(a),d=replaceAbbreviations(b);// Event names must start with a letter. Use only letters, numbers, and
// underscores. Don't use spaces.
// @see {@link https://support.google.com/analytics/answer/13316687?hl=en#:~:text=Event%20names%20can%20include%20English,letters%2C%20numbers%2C%20and%20underscores}
return "Nav_"+c+"_"+d});/**
 * Returns the name of the `gtag` `GA4` user navigation event, not longer
 * than `40` characters.
 * @param {string} fromPageType The type of the page the user navigated from.
 * @example `UserAreaResetPasswordRequestSuccess`.
 * @param {string} toPageType The type of the page the user navigated to.
 * @example `ViewFirstReservationPage`.
 * @return {string} The name of the `gtag` `GA4` user navigation event.
 */var buildGtagNavigationEventName$1 = buildGtagNavigationEventName;

/**
 * Fires the custom `gtag` `GA4` user navigation events.
 * @yields {void} .
 */function*handleGtagNavigationEvent(){throwInServerSideRendering("handleGtagNavigationEvent");const a=yield select(makeSelectGtagDataToSendWithEveryEvent()),b=yield select(makeSelectNavigationHistoryData$1()),c=b.last(),d=null==c?void 0:c.get("pageLocation"),e=null==c?void 0:c.get("pageType"),f=null==b?void 0:b.get(b.size-2),g=null==f?void 0:f.get("pageLocation"),h=null==f?void 0:f.get("pageType");if(h&&!(e===h&&d===g)){const b=Map({eventPastTense:buildGtagNavigationEventName$1(h,e),eventCategory:"UserNavigation",fromPageLocation:g,toPageLocation:d});yield call(analyticsEventSent,b.merge(a));}}

/**
 * Handles the navigation change.
 * @yields {void} .
 */function*handleNavigationChange(){throwInServerSideRendering("handleNavigationChange");const a=yield select(makeSelectTemplateCode()),b=yield select(makeSelectReactRouterPageType()),{locationBeforeTransitions:{pathname:d,search:e,hash:f},previousLocationBeforeTransitions:c}=yield select(makeSelectCurrentRouterState()),{hash:g}=c||{},h=yield select(makeSelectWebpagesLoadedAll()),i=1===h.size,j=yield select(makeSelectCurrentWebpageRouteReference()),k=yield select(makeSelectCurrentWebpageRouteTemplateCode()),l=yield select(selectLocationOriginFromServer);// `React` Router page type is used as a fallback in case the webpages template
// code is not available due to a redirect to the `TopMenuHashPage`.
// To Reproduce: From the navigation bar, navigate to "Your Bookings" While
// The user is not logged in. The user will be redirected to the sign in page.
// So we want to track the redirect navigation event.
let m=a||b;k&&j&&(m=j);// Don't use `yield all` here because we want to keep the order of the events.
yield put(pageLocationAddedToNavigationHistory$1(Map({pageLocation:""+l+d+e+f,pageType:m,wasOpenedInNewTab:i&&!(f!==g)}))),yield put(navigationHistoryPutIntoLocalStorage$1()),yield call(handleGtagNavigationEvent);}

function*handleRemoveLocationDataFromLocalStorage(){throwInServerSideRendering("handleRemoveLocationDataFromLocalStorage"),localStorage.removeItem(LOCAL_STORAGE_KEYS.NAVIGATION_HISTORY);}

function*handlePutNavigationHistoryIntoLocalStorage(){throwInServerSideRendering("handlePutNavigationHistoryIntoLocalStorage");const a=yield select(makeSelectNavigationHistoryData$1());localStorage.setItem(LOCAL_STORAGE_KEYS.NAVIGATION_HISTORY,JSON.stringify(a));}

function*rootNavigationTrackingSaga(){if(!isServerSideRendering()){const a=localStorage.getItem(LOCAL_STORAGE_KEYS.NAVIGATION_HISTORY);a&&(yield put(navigationHistoryRestoredFromLocalStorage$1(fromJS(JSON.parse(a))))),yield all([takeLatest(LOAD_WEBPAGES_SUCCESS,handleNavigationChange),takeLatest(PAGE_LOCATION_CHANGED,handleNavigationChange),takeLatest(NAVIGATION_HISTORY_REMOVED_FROM_LOCAL_STORAGE,handleRemoveLocationDataFromLocalStorage),takeLatest(PAGE_DATA_PUT_INTO_LOCAL_STORAGE,handlePutNavigationHistoryIntoLocalStorage)]);}}const injectedSagaConfiguration={key:REDUX_AND_SAGA_KEY,mode:DAEMON,saga:rootNavigationTrackingSaga};

const useBrowserOnlyInjectNavigationTrackingSagaAndReducerArgs=[{...injectedSagaConfiguration,saga:rootNavigationTrackingSaga},{key:REDUX_AND_SAGA_KEY,reducer:navigationHistoryReducer$1}],useBrowserOnlyInjectNavigationTrackingSagaAndReducer=()=>useBrowserOnlyInjectSagaAndReducer(...useBrowserOnlyInjectNavigationTrackingSagaAndReducerArgs);var useBrowserOnlyInjectNavigationHistorySagaAndReducer = useBrowserOnlyInjectNavigationTrackingSagaAndReducer;

const useBrowserOnlyNavigationHistoryListenersAndInjectSagaAndReducer=()=>{useBrowserOnlyInjectNavigationHistorySagaAndReducer();const a=usePageLocationChanged();useEffect(()=>{a();},[]);};var useBrowserOnlyNavigationHistoryListenersAndInjectSagaAndReducer$1 = useBrowserOnlyNavigationHistoryListenersAndInjectSagaAndReducer;

export { LOCAL_STORAGE_KEYS, NAVIGATION_HISTORY_REMOVED_FROM_LOCAL_STORAGE, NAVIGATION_HISTORY_RESTORED_FROM_LOCAL_STORAGE, PAGE_DATA_PUT_INTO_LOCAL_STORAGE, PAGE_LOCATION_ADDED_TO_NAVIGATION_HISTORY, PAGE_LOCATION_CHANGED, REDUX_AND_SAGA_KEY, makeSelectNavigationHistoryData$1 as makeSelectNavigationHistoryData, navigationHistoryPutIntoLocalStorage$1 as navigationHistoryPutIntoLocalStorage, navigationHistoryRemovedFromLocalStorage$1 as navigationHistoryRemovedFromLocalStorage, navigationHistoryRestoredFromLocalStorage$1 as navigationHistoryRestoredFromLocalStorage, pageLocationAddedToNavigationHistory$1 as pageLocationAddedToNavigationHistory, pageLocationChanged$1 as pageLocationChanged, useBrowserOnlyNavigationHistoryListenersAndInjectSagaAndReducer$1 as useBrowserOnlyNavigationHistoryListenersAndInjectSagaAndReducer, useNavigationHistoryPutIntoLocalStorage, useNavigationHistoryRemovedFromLocalStorage, usePageLocationAddedToNavigationHistory, usePageLocationChanged };
